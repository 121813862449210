<template>
  <!-- 
    {
      name: "",
      cc: "",
      city: "",
      address: "",
      level: "cachorros",
      role: "",
      email: "",
            
      companyId: "",
      companyName,

      createdAt: "",
      disabled: false,
      fcmToken: "",
      uid: "",
      updatedAt: Date.now(),
      points: 0,
      totalPoints: 0,
    }
   -->
  <main class="flex flex-col gap-5 bg-yellow w-full py-10">
    <h1 class="text-2xl md:text-4xl uppercase text-center font-bold">
      Crear usuario
    </h1>

    <form
      @submit.prevent="submit"
      ref="form"
      class="max-w-screen w-11/12 mx-auto flex flex-col gap-8 rounded bg-white p-5"
    >
      <aside class="grid grid-cols-2 gap-5">
        <div class="flex flex-col">
          <label class="font-bold">Nombre</label>
          <input
            type="text"
            name="name"
            required
            placeholder="Nombre completo"
            class="rounded-2xl px-3 py-2 border-1"
          />
        </div>
        <div class="flex flex-col">
          <label class="font-bold">C.C</label>
          <input
            type="text"
            name="cc"
            required
            placeholder="Cedula de ciudadania"
            class="rounded-2xl px-3 py-2 border-1"
          />
        </div>
        <div class="flex flex-col">
          <label class="font-bold">Correo</label>
          <input
            type="email"
            name="email"
            required
            placeholder="Correo"
            class="rounded-2xl px-3 py-2 border-1"
          />
        </div>
        <div class="flex flex-col">
          <label class="font-bold">Celular</label>
          <input
            type="tel"
            name="phone"
            minlength="8"
            required
            placeholder="Número de telefono"
            class="rounded-2xl px-3 py-2 border-1"
          />
        </div>
        <div class="flex flex-col">
          <label class="font-bold">Ciudad</label>
          <input
            type="text"
            name="city"
            required
            placeholder="Ciudad"
            class="rounded-2xl px-3 py-2 border-1"
          />
        </div>
        <div class="flex flex-col">
          <label class="font-bold">Dirección</label>
          <input
            type="text"
            name="address"
            required
            placeholder="Dirrección"
            class="rounded-2xl px-3 py-2 border-1"
          />
        </div>

        <div class="flex flex-col">
          <label class="font-bold">Rol</label>
          <select required name="role" class="rounded-2xl px-3 py-2 border-1">
            <option value="" disabled selected hidden>Selecciona un rol</option>
            <option value="OWNER">Propietario</option>
            <option value="WORKER">Colaborador</option>
          </select>
        </div>
        <div class="flex flex-col">
          <label class="font-bold">Región</label>
          <select required name="region" class="rounded-2xl px-3 py-2 border-1">
            <option>Selecciona una región</option>
            <option value="BOGOTÁ">BOGOTÁ</option>
            <option value="CALI">CALI</option>
            <option value="COSTA">COSTA</option>
            <option value="EJE CAFETERO">EJE CAFETERO</option>
            <option value="MEDELLÍN">MEDELLÍN</option>
            <option value="SANTANDER">SANTANDER</option>
            <option value="TOLUCA">TOLUCA</option>
          </select>
        </div>
        <div class="flex flex-col">
          <label class="font-bold">Compañia</label>
          <input
            type="text"
            name="cid"
            required
            placeholder="Tracksale de la compañia"
            class="rounded-2xl px-3 py-2 border-1"
          />
        </div>
      </aside>
      <button class="mx-auto bg-yellow rounded-lg font-bold px-5 py-2">
        Crear
      </button>
    </form>

    <dialog class="modal" v-show="configModal.show">
      <aside
        class="flex flex-col gap-3 max-w-screen-modal w-10/12 mx-auto rounded bg-white px-10 py-5"
      >
        <h3
          v-if="configModal.type == 'info' || configModal.type == 'action'"
          class="text-2xl text-center uppercase font-bold text-red-600"
        >
          ¡Advertencia!
        </h3>
        <h3
          v-if="configModal.type === 'success'"
          class="text-2xl text-center uppercase font-bold text-green-700"
        >
          ¡En hora buena!
        </h3>
        <p class="text-lx text-center" v-html="configModal.text"></p>
        <div class="flex justify-end gap-4">
          <button
            @click="() => (configModal.show = false)"
            class="text-red-500 rounded px-3 py-2 font-bold"
          >
            Cerrar
          </button>
          <button
            v-if="configModal.type === 'action'"
            @click="() => saveUser()"
            class="bg-green-600 text-white rounded px-3 py-2 font-bold"
          >
            Si, continuar
          </button>
        </div>
      </aside>
    </dialog>
    <Loader :isLoading="isLoading" />
  </main>
</template>

<script>
import { db, auth } from "../../firebase";
import Loader from "../../components/Loader.vue";
import firebase from "firebase/app";

export default {
  components: { Loader },
  data: () => ({
    companies: [],
    isLoading: false,
    configModal: {
      show: false,
      type: "",
      text: "",
    },
    userData: {},
  }),
  async mounted() {
    try {
      this.isLoading = true;
      const { docs } = await db.collection("companies").get();
      this.companies = docs.map((doc) => ({
        cid: doc.data().cid,
        name: doc.data().name,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async searchCompany({ cid }) {
      try {
        const company = await db.collection("companies").doc(cid).get();

        if (!company.exists)
          return {
            error: true,
            message: `La compañia "${cid}" no existe.`,
            data: null,
          };

        return { error: false, message: "ok", data: company.data() };
      } catch (error) {
        return {
          error: true,
          message: `La compañia "${cid}" no existe.`,
          data: null,
        };
      }
    },
    async submit() {
      try {
        const { elements } = this.$refs.form;
        const now = Date.now();

        const cid = elements.namedItem("cid").value;
        this.isLoading = true;
        const company = await this.searchCompany({ cid });

        if (company.error) {
          this.configModal.show = true;
          this.configModal.type = "info";
          this.configModal.text = `La compañia <strong>"${cid}"</strong> no se encuentra registrada, por favor validar su información.`;
          return;
        }

        if (
          elements.namedItem("role").value === "OWNER" &&
          company.data.owner != ""
        ) {
          this.configModal.show = true;
          this.configModal.type = "info";
          this.configModal.text = `Esta empresa ya cuenta con un propietario asignado.`;

          return;
        }

        if (
          elements.namedItem("role").value === "WORKER" &&
          company.data.maxWorkers === company.data.workers
        ) {
          this.configModal.show = true;
          this.configModal.type = "info";
          this.configModal.text = `Esta empresa ya no tiene cupos disponibles.`;

          return;
        }

        // TODO: Agregar select region
        this.userData = {
          uid: "",
          avatar: "",
          imgUrl: "",
          name: elements.namedItem("name").value,
          cc: elements.namedItem("cc").value,
          email: elements.namedItem("email").value,
          phone: elements.namedItem("phone").value,
          city: elements.namedItem("city").value,
          address: elements.namedItem("address").value,
          region: elements.namedItem("region").value,
          level: "cachorros",
          role: elements.namedItem("role").value,
          companyId: cid,
          companyName: company.data.name,
          createdAt: now,
          disabled: false,
          fcmToken: "",
          updatedAt: now,
          points: 0,
          totalPoints: 0,
          vip: false,
        };

        this.configModal.show = true;
        this.configModal.type = "action";
        this.configModal.text = `
          Se creará el usuario <strong>"${this.userData.name}"</strong> y estará asociado a la compañia <strong>"${this.userData.companyName}"</strong> con tracksales de
          <strong>"${this.userData.companyId}"</strong>. <br />
          ¿Desea continuar con la creación?
        `;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async createOAuthUser() {
      try {
        const token = await auth.currentUser.getIdToken();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          email: this.userData.email,
          password: this.userData.cc,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const req = await fetch(
          "https://us-central1-peluditos-club-mars.cloudfunctions.net/createUser",
          requestOptions
        );

        if (!req.ok) return { error: true, message: res.message, data: null };

        const res = await req.json();

        if (!res.success)
          return { error: true, message: res.message, data: null };

        return { error: false, message: "ok", data: res.data };
      } catch (error) {
        console.log(error);
        return {
          error: true,
          message: "Error al realizar la petición",
          data: null,
        };
      }
    },
    async saveUser() {
      try {
        this.configModal.show = false;
        this.isLoading = true;
        const OAuthUser = await this.createOAuthUser();

        if (OAuthUser.error) {
          this.configModal.show = true;
          this.configModal.type = "info";
          this.configModal.text =
            "Error al registrar al usuario, intentalo mas tarde.";
          return;
        }

        this.userData.uid = OAuthUser.data.uid;

        const batch = db.batch();

        batch.set(db.collection("users").doc(this.userData.uid), {
          ...this.userData,
        });

        if (this.userData.role === "OWNER") {
          batch.update(
            db.collection("companies").doc(this.userData.companyId),
            {
              owner: this.userData.uid,
            }
          );

          batch.update(db.collection("--stats--").doc("stats"), {
            userCount: firebase.firestore.FieldValue.increment(1),
            ownerCount: firebase.firestore.FieldValue.increment(1),
          });
        } else {
          batch.update(
            db.collection("companies").doc(this.userData.companyId),
            {
              workers: firebase.firestore.FieldValue.increment(1),
            }
          );

          batch.update(db.collection("--stats--").doc("stats"), {
            userCount: firebase.firestore.FieldValue.increment(1),
            workerCount: firebase.firestore.FieldValue.increment(1),
          });
        }

        await batch.commit();

        this.configModal.show = true;
        this.configModal.type = "success";
        this.configModal.text = `Usuario registrado correctamente UID: <strong>"${OAuthUser.data.uid}"</strong>`;
      } catch (error) {
        console.log({ error });
        this.configModal.show = true;
        this.configModal.type = "info";
        this.configModal.text = `Error al registrar al usuario, intentalo mas tarde.`;
        return;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.modal {
  width: 100dvw;
  height: 100dvh;
  background: #00000080;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-w-screen {
  max-width: 1200px !important;
}

.max-w-screen-modal {
  max-width: 700px !important;
}

.border-1 {
  border: 2px solid rgba(0, 0, 0, 0.292) !important;
  border-radius: 0.5rem;
}
</style>
